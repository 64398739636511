// jQueryはcdnで読み込み
// @types/jqueryを読み込んでいるのでエラーは出ないようにしている
/// <reference path='./typing.d.ts' />
/// <reference path='./_common.ts' />
$(function() {
    let APP = {
        initAccordion: function() {
        return $('.js-accordion-title').each(function() {
          var $body, $self, className;
          $self = $(this);
          className = 'is-open';
          $body = $self.next('.js-accordion-body');
          return $self.on('click', function() {
            $self.toggleClass(className);
            return $body.slideToggle().toggleClass(className);
          });
        });
      },
      init: function() {
        return this.initAccordion();
      }
    }
});

